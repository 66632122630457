<template>
    <v-main class="justify-center align-center">
        <v-img width="180" class="ma-auto" src="/images/logo.svg"></v-img>
    </v-main>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
    computed: {
        ...mapState(["accessToken"]),
    },
    mounted() {
        this.setShowsHeader(false);
        this.setShowsNavigation(false);
        this.reroute();
    },
    destroyed() {
        this.setShowsHeader(true);
        this.setShowsNavigation(true);
    },
    methods: {
        ...mapMutations("ui", {
            setShowsHeader: "header/setShows",
            setShowsNavigation: "setShowsNavigation",
        }),
        reroute() {
            setTimeout(() => {
                if (this.accessToken) this.$router.replace("/home");
                else this.$router.replace("/login");
            }, 1000);
        },
    },
};
</script>
