import axios from "@/plugins/axios";

let url = "/api/v1/fairs";

export default {
    gets(_fair, data){
        return axios.get(`${url}/${_fair}/products`, data).then(result => result.data);
    },
    post(_fair, data){
        return axios.post(`${url}/${_fair}/products`, data).then(result => result.data);
    },
    put(_fair, data){
        return axios.put(`${url}/${_fair}/products`, data).then(result => result.data);
    },
    delete(_fair, data){
        return axios.delete(`${url}/${_fair}/products`, data).then(result => result.data);
    }
}
