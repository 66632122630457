import axios from "@/plugins/axios";

import areas from "./areas";
import banners from "./banners";
import boards from "./boards";
import center from "./center";
import forms from "./forms";
import levels from "./levels";
import popups from "./popups";
import setting from "./setting";
import shop from "./shop";
import policies from "./policies";
import users from "./users";
export default {
    areas,
    banners,
    boards,
    center,
    forms,
    levels,
    popups,
    setting,
    shop,
    policies,
    users,
};
