import axios from "@/plugins/axios";

import claims from "./claims";
import files from "./files";

let url = "/api/v1/me/purchase-orders";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data._id}`).then((result) => result.data);
    },
    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    cancel(data) {
        return axios.put(`${url}/${data._id}/cancel`, data).then((result) => result.data);
    },

    claims,
    files,
};
