export const LEFT_NAV_MODES = {
    MENU: "header.leftNavMode:MENU",
    BACK: "header.leftNavMode:BACK",
    HOME: "header.leftNavMode:HOME",
};
export const RIGHT_NAV_MODES = {
    NONE: "header.rightNavMode:NONE",
    USER: "header.rightNavMode:USER",
    SHOP_SEARCH: "header.rightNavMode:SHOP_SEARCH",
    COMMUNITY_SEARCH: "header.rightNavMode:COMMUNITY_SEARCH",
};

export const header = {
    namespaced: true,
    state: {
        shows: true,
        title: undefined,
        leftNavMode: LEFT_NAV_MODES.MENU,
        rightNavMode: RIGHT_NAV_MODES.NONE,
    },
    mutations: {
        setShows(state, shows) {
            state.shows = shows;
        },
        setTitle(state, title) {
            state.title = title;
        },
        setLeftNavMode(state, leftNavMode) {
            if (Object.values(LEFT_NAV_MODES).includes(leftNavMode)) state.leftNavMode = leftNavMode;
            else alert("요청하신 leftNavMode가 없습니다.");
        },
        setRightNavMode(state, rightNavMode) {
            if (Object.values(RIGHT_NAV_MODES).includes(rightNavMode)) state.rightNavMode = rightNavMode;
            else alert("요청하신 rightNavMode가 없습니다.");
        },
    },
    actions: {},
    modules: {},
};
