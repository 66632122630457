module.exports = {

    // 공용
    common: {
        "로그인": "登录",
        "로그아웃": "登出",
        "글쓰기": "写作",
    },

    // 메인페이지
    main: {
        section1: "第1节内容 OTL",
        section2: {
            title: "第 2 节标题",
            content: "第 2 节内容"
        }
    }
};