module.exports = {

    // 공용
    common: {
        "로그인": "로그인",
        "로그아웃": "로그아웃",
        "글쓰기": "글쓰기",
    },

    // 메인페이지
    main: {
        section1: "섹션1 내용ㅇㅅㅇ",
        section2: {
            title: "타이틀",
            content: "내용"
        }
    },
    
    mypage: {
        "회원정보수정": "회원정보수정"
    }
};