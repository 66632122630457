import axios from "@/plugins/axios";

let url = "/api/editor";

export default {
    postPhoto({ file }) {
        var formData = new FormData();
        formData.append("file", file);
        var headers = { "Content-type": "multipart/formdata" };
        return axios.post(`${url}/photo`, formData, { headers }).then((result) => result.data);
    },
};
