var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-main', {
    staticClass: "justify-center align-center"
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "width": "180",
      "src": "/images/logo.svg"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }