import Vue from "vue";
import VueRouter from "vue-router";
import axios from "../plugins/axios";
import { i18n } from "../plugins/vue-i18n";

import Main from "../pages/client/Main.vue";
import { PurchaseOrderingRouter } from "./purchase-ordering.router";
import { USER_SCOPES } from "../assets/variables/constants";

Vue.use(VueRouter);

const routes = [
    /////////////////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    /////////////////////////////////////////////////////////////////////////////////////
    {
        // Main 페이지
        path: "/",
        component: Main,
    },
    {
        path: "/common/kcp/cert/request",
        component: () => import("../pages/common/kcp/KCPCertRequest.vue"),
        props: true,
    },
    {
        path: "/common/kcp/cert/response",
        component: () => import("../pages/common/kcp/KCPCertResponse.vue"),
        props: true,
    },
    {
        // 로그인
        path: "/login",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/main.vue"),
        props: true,
    },
    {
        // Home 페이지
        path: "/home",
        component: () => import(/* webpackChunkName: "home" */ "../pages/client/home/HomePage.vue"),
    },

    {
        // 발주 - 날짜선택
        path: "/shinjin/order/date",
        component: () => import(/* webpackChunkName: "shinjin" */ "../pages/client/shinjin/OrderDatePage.vue"),
    },
    {
        // 발주 - 발송정보입력
        path: "/shinjin/order/send-input",
        component: () => import(/* webpackChunkName: "shinjin" */ "../pages/client/shinjin/OrderSendInputPage.vue"),
    },
    {
        // 발주 - 상품선택
        path: "/shinjin/order/product-selection",
        component: () => import(/* webpackChunkName: "shinjin" */ "../pages/client/shinjin/OrderProductSelectionPage.vue"),
    },
    {
        // 발주 - 발주내역확인
        path: "/shinjin/order/complete",
        component: () => import(/* webpackChunkName: "shinjin" */ "../pages/client/shinjin/OrderCompletePage.vue"),
    },
    {
        // 발주이력 - 발주이력리스트
        path: "/shinjin/history/list",
        component: () => import(/* webpackChunkName: "shinjin" */ "../pages/client/shinjin/HistoryListPage.vue"),
    },
    {
        // 발주이력 - 발주이력상세
        path: "/shinjin/history/view",
        component: () => import(/* webpackChunkName: "shinjin" */ "../pages/client/shinjin/HistoryViewPage.vue"),
    },
    {
        // 문의 및 제휴
        path: "/form/inquiry-partner",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/InquiryPartner.vue"),
        props: true,
    },
    {
        // 공지사항
        path: "/center/notice",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
        props: true,
    },
    {
        // 공지사항
        path: "/center/notice/:_notification",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
        props: true,
    },
    {
        // 자주묻는질문
        path: "/center/faqs",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/faq.vue"),
        props: true,
    },
    {
        // 개인정보처리방침
        path: "/privacy",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/PolicyPage.vue"),
        props: true,
    },
    {
        // EULA
        path: "/EULA",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/PolicyPage.vue"),
        props: true,
    },
    {
        // user-agent
        path: "/device-info",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/DeviceInfo.vue"),
        props: true,
    },

    /////////////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    /////////////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: "/console",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Main.vue"),
        props: true,
        scope: [USER_SCOPES.console.value],
    },

    {
        // 관리자 - 게시판관리
        path: "/console/boards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardList.vue"),
        scope: [USER_SCOPES.console.value],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
        scope: [USER_SCOPES.console.value],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
        scope: [USER_SCOPES.console.value],
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormList.vue"),
        scope: [USER_SCOPES.console.value],
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms/:_form",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormView.vue"),
        scope: [USER_SCOPES.console.value],
    },
    // {
    //     // 관리자 - 회원등급
    //     path: "/console/users/levels",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/LevelList.vue"),
    //     props: true,
    //     scope: [USER_SCOPES.console.value],
    // },
    // {
    //     // 관리자 - 탈퇴회원 정보
    //     path: "/console/users/withdrawn",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/WithdrawnList.vue"),
    //     props: true,
    //     scope: [USER_SCOPES.console.value],
    // },
    {
        // 관리자 - 사용자 관리 - 사용자
        path: "/console/users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserList.vue"),
        props: true,
        scope: [USER_SCOPES.executive.value],
    },
    {
        // 관리자 - 사용자 관리 - 사용자 그룹
        path: "/console/user-groups",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserGroupList.vue"),
        props: true,
        scope: [USER_SCOPES.executive.value],
    },
    {
        // 관리자 - 사용자 관리 - 관리자
        path: "/console/admins",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/AdminList.vue"),
        props: true,
        scope: [USER_SCOPES.executive.value],
    },
    // {
    //     // 관리자 - 사용자 정보
    //     path: "/console/users/create",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
    //     props: true,
    //     scope: [USER_SCOPES.console.value],
    // },
    // {
    //     // 관리자 - 사용자 정보
    //     path: "/console/users/:_user",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
    //     props: true,
    //     scope: [USER_SCOPES.console.value],
    // },
    {
        // 관리자 - 공지사항
        path: "/console/center/notifications",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Notification.vue"),
        props: true,
        scope: [USER_SCOPES.console.value],
    },
    // {
    //     // 관리자 - FAQ
    //     path: "/console/center/faqs",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Faq.vue"),
    //     props: true,
    //     scope: [USER_SCOPES.console.value],
    // },
    // {
    //     // 관리자 - 1:1문의
    //     path: "/console/center/questions",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Question.vue"),
    //     props: true,
    //     scope: [USER_SCOPES.console.value],
    // },
    {
        // 관리자 - 서비스 이용약관
        path: "/console/center/policies",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/policies/PolicyListPage.vue"),
        props: true,
        scope: [USER_SCOPES.console.value],
    },
    // {
    //     // 관리자 - 배너관리
    //     path: "/console/banners",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerList.vue"),
    //     props: true,
    //     scope: [USER_SCOPES.console.value],
    // },
    // {
    //     // 관리자 - 배너관리
    //     path: "/console/banners/create",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
    //     props: true,
    //     scope: [USER_SCOPES.console.value],
    // },
    // {
    //     // 관리자 - 배너관리
    //     path: "/console/banners/:_banner",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
    //     props: true,
    //     scope: [USER_SCOPES.console.value],
    // },
    // {
    //     // 관리자 - 팝업관리
    //     path: "/console/popups",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/popup/PopupList.vue"),
    //     props: true,
    //     scope: [USER_SCOPES.console.value],
    // },
    // {
    //     // 관리자 - SMTP
    //     path: "/console/smtp",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/SmtpView.vue"),
    //     props: true,
    //     scope: [USER_SCOPES.console.value],
    // },
    {
        // 관리자 - 비밀번호
        path: "/console/password",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/PasswordView.vue"),
        props: true,
        scope: [USER_SCOPES.console.value],
    },

    ...PurchaseOrderingRouter,
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to) {
        return { selector: to.hash || to, behavior: "smooth" };
    },
});

router.beforeEach((to, from, next) => {
    try {
        const accessToken = sessionStorage.getItem("accessToken");
        axios.defaults.headers.common["Authorization"] = !!accessToken ? `Bearer ${accessToken}` : "";
        axios.defaults.headers.common["Accept-Language"] = i18n.locale;

        const route = routes.find((route) => route.path == to.path);
        if (!route?.scope) next();
        else {
            if (!accessToken) {
                if (0 <= to.path.indexOf(USER_SCOPES.console.value)) {
                    next();
                    return;
                } else throw new Error("로그인 후 이용가능합니다");
            }

            const payload = JSON.parse(atob(accessToken.split(".")[1]));
            const scope = payload?.scope || [];

            if (!scope.find((scope) => route.scope.includes(scope))) {
                throw new Error("접근권한이 없습니다");
            }

            next();
        }
    } catch (error) {
        alert(error.message);
        window.location.href = routes.find((route) => route.path == from.path)?.path ?? routes.find((route) => route.path == "/")?.path ?? "about:blank";
    }
});

export default router;
