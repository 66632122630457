export {}

//////////////////////////////////////////////////////////////////////////////////////////////////
// Number Prototype
//////////////////////////////////////////////////////////////////////////////////////////////////
// 
//
//
//////////////////////////////////////////////////////////////////////////////////////////////////
if (!Number.prototype.hasOwnProperty('format')){
    Number.prototype.format = function(n: number, x: number): string {
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
        return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
    };
}
if (!Number.prototype.hasOwnProperty('toDate')){
    Number.prototype.toDate = function(): string {
        var date = new Date(Number(this));
        return [date.getFullYear(), (date.getMonth() + 1).toString().padStart(2, '0'), date.getDate().toString().padStart(2, '0')].join("-");
    };
}
if (!Number.prototype.hasOwnProperty('toDateTime')){
    Number.prototype.toDateTime = function(): string {
        var date = new Date(Number(this));
        return this.toDate() + " " + [date.getHours().toString().padStart(2, '0'), date.getMinutes().toString().padStart(2, '0'), date.getSeconds().toString().padStart(2, '0')].join(":");
    };
    
}
if (!Number.prototype.hasOwnProperty("toAgo")) {
    Number.prototype.toAgo = function(): string {
        const timeValue = new Date(Number(this));
        const today = new Date();

        const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
        if (betweenTime < 1) return "방금 전";
        if (betweenTime < 60) return `${betweenTime}분 전`;

        const betweenTimeHour = Math.floor(betweenTime / 60);
        if (betweenTimeHour < 24) return `${betweenTimeHour}시간 전`;

        const betweenTimeDay = Math.floor(betweenTimeHour / 24);
        if (betweenTimeDay < 7) return `${betweenTimeDay}일 전`;

        const betweenTimeWeek = Math.floor(betweenTimeDay / 7);
        if (betweenTimeDay < 366) return `${betweenTimeWeek}주 전`;

        const betweenTimeYear = Math.floor(betweenTimeDay / 365);
        return `${betweenTimeYear}년 전`;
    };
}

//////////////////////////////////////////////////////////////////////////////////////////////////
// String Prototype
//////////////////////////////////////////////////////////////////////////////////////////////////
//
//
//
//////////////////////////////////////////////////////////////////////////////////////////////////
if (!String.prototype.hasOwnProperty('phoneNumberFormat')) {
    String.prototype.phoneNumberFormat = function(): string{
        var phone = this.replace(/-/g, '');
        return (this?.length == phone?.length) ? [phone.substr(0, 3), phone.substr(3, 4), phone.substr(7, 4)].join('-') : this.toString();
    };
}

if (!String.prototype.hasOwnProperty('toDate')) {
    String.prototype.toDate = function(): string {
        var timestamp = (this.length == 13 && !this.match(/[^0-9]/)) ? parseInt(this.toString()) : undefined;
        var date = new Date(timestamp || this.toString());
        return [date.getFullYear(), (date.getMonth() + 1).toString().padStart(2, "0"), date.getDate().toString().padStart(2, "0")].join("-");
    };
}

if (!String.prototype.hasOwnProperty('toDateTime')) {
    String.prototype.toDateTime = function(): string{
        var timestamp = (this.length == 13 && !this.match(/[^0-9]/)) ? parseInt(this.toString()) : undefined;
        var date = new Date(timestamp || this.toString());
        return this.toDate() + " " + [date.getHours().toString().padStart(2, "0"), date.getMinutes().toString().padStart(2, "0"), date.getSeconds().toString().padStart(2, "0")].join(":");
    };
}
if (!String.prototype.hasOwnProperty("toAgo")) {
    String.prototype.toAgo = function(): string {
        const timestamp = (this.length == 13 && !this.match(/[^0-9]/)) ? parseInt(this.toString()) : undefined;
        const timeValue = new Date(timestamp || this.toString());

        const today = new Date();

        const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
        if (betweenTime < 1) return "방금 전";
        if (betweenTime < 60) return `${betweenTime}분 전`;

        const betweenTimeHour = Math.floor(betweenTime / 60);
        if (betweenTimeHour < 24) return `${betweenTimeHour}시간 전`;

        const betweenTimeDay = Math.floor(betweenTimeHour / 24);
        if (betweenTimeDay < 7) return `${betweenTimeDay}일 전`;

        const betweenTimeWeek = Math.floor(betweenTimeDay / 7);
        if (betweenTimeDay < 366) return `${betweenTimeWeek}주 전`;

        const betweenTimeYear = Math.floor(betweenTimeDay / 365);
        return `${betweenTimeYear}년 전`;
    };
}

//////////////////////////////////////////////////////////////////////////////////////////////////
// Date Prototype
//////////////////////////////////////////////////////////////////////////////////////////////////
//
//
//
//////////////////////////////////////////////////////////////////////////////////////////////////
if (!Date.prototype.hasOwnProperty('addYear')){
    Date.prototype.addYear = function(year: number = 0): number{
        return this.setFullYear(this.getFullYear() + year);
    }
}
if (!Date.prototype.hasOwnProperty('addMonth')){
    Date.prototype.addMonth = function(month: number = 0): number{
        return this.setMonth(this.getMonth() + month);
    }
}
if (!Date.prototype.hasOwnProperty('addDate')){
    Date.prototype.addDate = function(days: number = 0): number{
        return this.setDate(this.getDate() + days);
    }
}
if (!Date.prototype.hasOwnProperty('addHours')){
    Date.prototype.addHours = function(hour: number = 0): number{
        return this.setHours(this.getHours() + hour);
    }
}
if (!Date.prototype.hasOwnProperty('addMinutes')){
    Date.prototype.addMinutes = function(minute: number = 0): number{
        return this.setMinutes(this.getMinutes() + minute);
    }
}
if (!Date.prototype.hasOwnProperty('addSeconds')){
    Date.prototype.addSeconds = function(second: number = 0): number{
        return this.setSeconds(this.getSeconds() + second);
    }
}

//////////////////////////////////////////////////////////////////////////////////////////////////
// Object Prototype
//////////////////////////////////////////////////////////////////////////////////////////////////
//
//
//
//////////////////////////////////////////////////////////////////////////////////////////////////
if (!Object.hasOwnProperty('assignDefined')){
    Object.assignDefined = function(target, ...sources): object {
        for (const source of sources) {
            for (const key of this.keys(source)) {
                const val = source[key];
                if (val !== undefined) {
                    target[key] = val;
                }
            }
        }
        return target;
    };
}

//////////////////////////////////////////////////////////////////////////////////////////////////
// Array Prototype
//////////////////////////////////////////////////////////////////////////////////////////////////
//
//
//
//////////////////////////////////////////////////////////////////////////////////////////////////
if (!Array.hasOwnProperty('shuffle')){
    Array.shuffle = function(array: any[]): any[]{
        for(var i = array.length - 1; i > 0; i--){
            const j = Math.floor(Math.random() * ( i + 1 ));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
}
