import api from "../../api";

export const shop = {
    namespaced: true,
    state: {
        categories: [],
    },
    mutations: {
        setCategories(state, { categories }) {
            state.categories = categories;
        },
    },
    actions: {
        async getCategories({ commit }) {
            const { categories } = await api.v1.shop.categories.getCategories();
            commit("setCategories", { categories });
        },
    },
    modules: {},
};
