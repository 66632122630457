import Vue from "vue";
import Vuex from "vuex";
import axios from "../plugins/axios";
import api from "../api";
import { honja } from "./honja";
import { shop } from "./shop";
import { ui } from "./ui";

Vue.use(Vuex);

var refreshToken = sessionStorage.getItem("refreshToken");
var accessToken = sessionStorage.getItem("accessToken");

var payload = JSON.parse(atob(accessToken?.split(".")[1] || "") || "{}");
var { scope = [], isOrderDisabled = false } = payload || {};

export default new Vuex.Store({
    state: {
        refreshToken,
        accessToken,
        payload,
        scope,
        isOrderDisabled,

        user: undefined,
        likes: undefined,
        setting: undefined,
        bookmarks: undefined,
        screenTime: 0,
        screenTimer: null,
        remainCoins: {
            activity: null,
            screenTime: null,
        },
        beforeunload: null,

        _carts: JSON.parse(sessionStorage.getItem("_carts") || "[]"), // 장바구니 상품
        _pickups: JSON.parse(sessionStorage.getItem("_pickups") || "[]"), // 주문 픽업상품

        agreements: JSON.parse(sessionStorage.getItem("agreements") || "[]"), // 회원약관 승인
    },
    mutations: {
        agreements(state, agreements) {
            state.agreements = agreements;

            sessionStorage.setItem("agreements", JSON.stringify(agreements));
        },
        login(state, { refreshToken, accessToken }) {
            state.refreshToken = refreshToken;
            state.accessToken = accessToken;

            state.payload = JSON.parse(atob(accessToken?.split(".")[1] || "") || "{}");
            state.scope = state.payload?.scope || [];
            state.isOrderDisabled = state.payload?.isOrderDisabled;

            axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

            sessionStorage.setItem("refreshToken", refreshToken);
            sessionStorage.setItem("accessToken", accessToken);
        },
        logout(state) {
            state.refreshToken = undefined;
            state.accessToken = undefined;

            state.payload = {};
            state.scope = [];
            state.user = undefined;
            state.likes = undefined;
            state.bookmarks = undefined;

            axios.defaults.headers.common["Authorization"] = "";

            sessionStorage.removeItem("refreshToken");
            sessionStorage.removeItem("accessToken");
        },

        setUser(state, { user }) {
            state.user = user;
        },
        setLikes(state, { likes }) {
            state.likes = likes;
        },
        setSetting(state, { setting }) {
            state.setting = setting;
        },
        setBookmarks(state, { bookmarks }) {
            state.bookmarks = bookmarks;
        },
        setScreenTime(state, { screenTime }) {
            state.screenTime = screenTime;
        },
        setScreenTimer(state, { screenTimer }) {
            state.screenTimer = screenTimer;
        },
        setRemainCoins(state, { remainCoins }) {
            state.remainCoins = remainCoins;
        },
        setBeforeunload(state, { beforeunload }) {
            state.beforeunload = beforeunload;
        },

        addToCart(state, _carts) {
            state._carts = _carts;
            sessionStorage.setItem("_carts", JSON.stringify(_carts));
        },
        checkout(state, _pickups) {
            state._pickups = _pickups;
            sessionStorage.setItem("_pickups", JSON.stringify(_pickups));
        },
    },
    actions: {
        agreements({ commit }, _terms) {
            commit("agreements", _terms);
        },
        async login({ commit, dispatch }, { username, password }) {
            var { refreshToken } = await api.auth.getRefreshToken({ username, password });
            var { accessToken } = await api.auth.getAccessToken(refreshToken);
            commit("login", { refreshToken, accessToken });
            await dispatch("startScreenTime");
        },
        async logout({ commit, dispatch }) {
            await dispatch("stopScreenTime");
            commit("logout");
        },
        async refresh({ commit }, refreshToken) {
            var { accessToken } = await api.auth.getAccessToken(refreshToken);

            commit("login", { refreshToken, accessToken });
        },

        async getUser({ commit }) {
            const { user } = await api.v1.me.get();
            commit("setUser", { user });
        },
        async getLikes({ commit }) {
            const { likes } = await api.v1.me.likes.gets();
            commit("setLikes", { likes });
        },
        async getBookmarks({ commit }) {
            const { bookmarks } = await api.v1.me.bookmarks.gets();
            commit("setBookmarks", { bookmarks });
        },
        async getRemainCoins({ commit, state }) {
            if (state.payload?._user) {
                const { success, ...remainCoins } = await api.v1.me.coins.remain();
                commit("setRemainCoins", { remainCoins });
            }
        },
        async startScreenTime({ commit, dispatch, state }, { intervalTime = 1000 } = {}) {
            if (state.beforeunload) window.removeEventListener("beforeunload", state.beforeunload);
            if (state.screenTimer) clearInterval(state.screenTimer);
            if (state?.payload?._user) {
                const beforeunload = (event) => {
                    dispatch("stopScreenTime");
                    event?.preventDefault?.();
                    event.returValue = false;
                };
                window.addEventListener("beforeunload", beforeunload);
                commit("setBeforeunload", { beforeunload });
                const screenTimer = setInterval(() => commit("setScreenTime", { screenTime: state.screenTime + intervalTime }), intervalTime);
                commit("setScreenTimer", { screenTimer });
                await dispatch("getUser");
            }
            if (state.screenTime) commit("setScreenTime", { screenTime: 0 });

            const { setting } = await api.v1.setting.get();
            commit("setSetting", { setting });
        },
        async stopScreenTime({ commit, state, getters }) {
            const { beforeunload, screenTimer, screenTime } = state;
            if (state?.payload?._user) {
                const { screenTime } = state.user || {};
                await api.v1.me.put({
                    _id: state.payload._user,
                    screenTime: {
                        ...screenTime,
                        total: (screenTime?.total || 0) + (state?.screenTime || 0),
                        afterLastBreakpoint: getters.screenTime,
                    },
                });
            }
            if (beforeunload) {
                window.removeEventListener("beforeunload", beforeunload);
                commit("setBeforeunload", { beforeunload: null });
            }
            if (screenTimer) {
                clearInterval(screenTimer);
                commit("setScreenTimer", { screenTimer: null });
            }
            if (screenTime) commit("setScreenTime", { screenTime: 0 });
        },

        addToCart({ commit }, _carts) {
            commit("addToCart", _carts);
        },

        checkout({ commit }, _pickups) {
            commit("checkout", _pickups);
        },
    },
    getters: {
        screenTime(state) {
            if (state.user) return state.screenTime + state?.user?.screenTime?.afterLastBreakpoint;
            else return null;
        },

        millisecondsUntilCoin(state, getters) {
            if (getters.screenTime) return state?.setting?.coin?.screenTime?.milliseconds - getters.screenTime;
        },

        hasCoinBonus(state, getters) {
            return !isNaN(getters.millisecondsUntilCoin) && getters.millisecondsUntilCoin <= 0;
        },

        coinItemsActive(state) {
            return state?.user?.coinItemsActive || [];
        },
    },
    modules: {
        honja,
        shop,
        ui,
    },
});
