import CryptoJS from "crypto-js";

const CRYPTO_SECRET_KEY = process.env.VUE_APP_CRYPTO_SECRET_KEY;
const CRYPTO_SECRET_IV = process.env.VUE_APP_CRYPTO_SECRET_IV;

export const encrypt = (planText) => {
    return CryptoJS.AES.encrypt(planText, CryptoJS.enc.Utf8.parse(CRYPTO_SECRET_KEY), { iv: CryptoJS.enc.Utf8.parse(CRYPTO_SECRET_IV) }).toString();
}
export const decrypt = (cyperText) => {
    return CryptoJS.AES.decrypt(cyperText, CryptoJS.enc.Utf8.parse(CRYPTO_SECRET_KEY), { iv: CryptoJS.enc.Utf8.parse(CRYPTO_SECRET_IV) }).toString(CryptoJS.enc.Utf8);
}

export default {
    encrypt,
    decrypt
}
