import axios from "@/plugins/axios";
import purchaseOrders from "./purchase-orders";

let url = "/api/v1/me";

export default {
    purchaseOrders,

    get() {
        return axios.get(url).then((result) => result.data);
    },
    put(data) {
        return axios.put(url, data).then((result) => result.data);
    },
    withdraw(data) {
        return axios.post(`${url}/withdraw`, data).then((result) => result.data);
    },
};
