import { community } from "./ui-commnuity";
import { header } from "./ui-header";
export const ui = {
    namespaced: true,
    state: {
        showsDrawer: false,
        showsNavigation: true,
    },
    mutations: {
        setShowsDrawer(state, showsDrawer) {
            state.showsDrawer = showsDrawer;
        },
        setShowsNavigation(state, showsNavigation) {
            state.showsNavigation = showsNavigation;
        },
    },
    actions: {},
    modules: {
        community,
        header,
    },
};
