import Vue from "vue";
import http from "http";
import https from "https";
import axios from "axios";
import store from "../store";

const httpAgent = new http.Agent({ keepAlive: true });
const httpsAgent = new https.Agent({ keepAlive: true });
const instance = axios.create({
    httpAgent,
    httpsAgent,
});

Vue.prototype.$http = instance;

// 1. request 핸들링
// instance.interceptors.request.use(config => {
//
//     return config;
// });

// 2. response(정상/에러) 핸들링
instance.interceptors.response.use(undefined, async (error) => {
    if (error.config && error.response) {
        switch (error.response.status) {
            case 400: {
                break;
            }
            case 401: {
                // accessToken 유효하지 않은경우
                if (store.state.refreshToken) {
                    try {
                        await store.dispatch("refresh", store.state.refreshToken);
                        error.config.headers["Authorization"] = `Bearer ${store.state.accessToken}`;
                    } catch (error) {
                        await store.dispatch("logout");
                    }
                    return instance.request(error.config);
                }
                break;
            }
            case 403: {
                if (/^\/api\/v1\/users\/nickname\/.*\/validate$/.test(error.config.url)) return;
                alert(error.response.data.message);
                break;
            }
        }
    }

    throw error;
});

export default instance;
