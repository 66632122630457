export const PURCHASE_ORDER_TYPES = {
    GIFTSET_ORDER: { text: "세트발주", textShorten: "세트발주", value: "GIFTSET_ORDER" },
    CUSTOMER_ORDER_BY_UNIT: { text: "택배발주-건별접수", textShorten: "택배건별", value: "CUSTOMER_ORDER_BY_UNIT" },
    CUSTOMER_ORDER_BY_BULK: { text: "택배발주-일괄접수", textShorten: "택배일괄", value: "CUSTOMER_ORDER_BY_BULK" },
    STORE_ORDER_BY_COMPANY: { text: "특정발주-물류입고", textShorten: "특정물류", value: "STORE_ORDER_BY_COMPANY" },
    STORE_ORDER_BY_COURIER: { text: "특정발주-택배입고", textShorten: "특정택배", value: "STORE_ORDER_BY_COURIER" },
    STORE_ORDER_BY_EXPRESS: { text: "특정발주-직접배송", textShorten: "특정직배", value: "STORE_ORDER_BY_EXPRESS" },
    BUNDANG_HANDLING_ORDER: { text: "분당배송", textShorten: "분당배송", value: "BUNDANG_HANDLING_ORDER" },

    ADMIN_UPLOADED_ORDER_SH: { text: "생협", textShorten: "생협", value: "ADMIN_UPLOADED_ORDER_SH" },
    ADMIN_UPLOADED_ORDER_CU: { text: "CU", textShorten: "CU", value: "ADMIN_UPLOADED_ORDER_CU" },
    ADMIN_UPLOADED_ORDER_BC: { text: "비앤씨", textShorten: "비앤씨", value: "ADMIN_UPLOADED_ORDER_BC" },
    ADMIN_UPLOADED_ORDER_DC: { text: "닷컴", textShorten: "닷컴", value: "ADMIN_UPLOADED_ORDER_DC" },

    ADMIN_MANUAL_ORDER_BULK: { text: "수동일괄", textShorten: "수동일괄", value: "ADMIN_MANUAL_ORDER_BULK" },
    ADMIN_MANUAL_ORDER_UNIT: { text: "수동건별", textShorten: "수동건별", value: "ADMIN_MANUAL_ORDER_UNIT" },
};

let { GIFTSET_ORDER, CUSTOMER_ORDER_BY_UNIT, CUSTOMER_ORDER_BY_BULK, STORE_ORDER_BY_COMPANY, STORE_ORDER_BY_COURIER, STORE_ORDER_BY_EXPRESS, BUNDANG_HANDLING_ORDER } = PURCHASE_ORDER_TYPES;

export const PURCHASE_ORDER_STATES = {
    ORDERED: { text: "처리대기", value: "ORDERED" },
    PREPARING: { text: "접수완료", value: "PREPARING" },
    PROCESSED: { text: "해피콜/입출고완료", value: "PROCESSED" },
    TRANSPORT: { text: "발송완료", value: "TRANSPORT" },
    CLAIM_RECEIVED: { text: "클레임", value: "CLAIM_RECEIVED" },
    CANCELED: { text: "발주취소", value: "CANCELED" },
    ERROR: { text: "오류", value: "ERROR" },
};

export const PURCHASE_ORDER_UPDATE_COLUMN = {
    ERROR: { text: "오류(진한빨강)", value: "ERROR" },
    TARGETSAT: { text: "입고발송일(연한빨강)", value: "TARGETSAT" },
    SENDER: { text: "보내시는분(연한주황)", value: "SENDER" },
    RECEIVER_NAME: { text: "받으시는분/이름(연한노랑)", value: "RECEIVER_NAME" },
    RECEIVER_PHONE: { text: "받으시는분/연락처(연한초록)", value: "RECEIVER_PHONE" },
    RECEIVER_ADDRESS: { text: "받으시는분/주소(연한파랑)", value: "RECEIVER_ADDRESS" },
    SHIPPINGMESSAGE: { text: "배송메시지(연한보라)", value: "SHIPPINGMESSAGE" },
    HAS_SAME_ORDERS: { text: "중복등록(진한주황)", value: "HAS_SAME_ORDERS" },
};

export const SITE_FEATURES = {
    GIFTSET_ORDER,
    CUSTOMER_ORDER_BY_UNIT,
    CUSTOMER_ORDER_BY_BULK,
    STORE_ORDER_BY_COMPANY,
    STORE_ORDER_BY_COURIER,
    STORE_ORDER_BY_EXPRESS,
    BUNDANG_HANDLING_ORDER,
};

export const USER_SCOPES = {
    executive: { text: "최고관리자", value: "executive" },
    console: { text: "관리자", value: "console" },
    GIFTSET_ORDER,
    CUSTOMER_ORDER_BY_UNIT,
    CUSTOMER_ORDER_BY_BULK,
    STORE_ORDER_BY_COMPANY,
    STORE_ORDER_BY_COURIER,
    STORE_ORDER_BY_EXPRESS,
    BUNDANG_HANDLING_ORDER,
};
