import billboards from "./billboards";
import contents from "./contents";
import locations from "./locations";
import notifications from "./notifications";
import products from "./products";
import quizzes from "./quizzes";
import tags from "./tags";
import tiles from "./tiles";

export default {
    billboards,
    contents,
    locations,
    notifications,
    products,
    quizzes,
    tags,
    tiles,
};
