import categories from "./categories";
import coupons from "./coupons";
import exhibitions from "./exhibitions";
import inquires from "./inquires";
import islands from "./islands";
import products from "./products";
import reviews from "./reviews";
import shippings from "./shippings";
import stores from "./stores";
import coinShopContact from "./coinShopContact";

export default {
    categories,
    coupons,
    exhibitions,
    inquires,
    islands,
    products,
    reviews,
    shippings,
    stores,
    coinShopContact
}
