import axios from "@/plugins/axios";

let url = "/api/console/shop/purchase-orders";

export default {
    post(file) {
        var headers = { "Content-Type": "multipart/form-data" };
        var formData = new FormData();
        formData.append("file", file);
        return axios.post(`${url}/files`, formData, { headers }).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/files/${data._id}`).then((result) => result.data);
    },
};
