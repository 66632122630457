import { getCurrentPosition } from "@/plugins/getCurrentPosition";

const kakao = window?.kakao?.maps;
const { Geocoder, Status } = kakao?.services || {};
const geocoder = Geocoder ? new Geocoder() : {};

export const location = {
    namespaced: true,

    state: {
        center: {
            lng: 127.032693842117,
            lat: 37.4835924256371,
            sido: "서울",
            gugun: "서초구",
        },

        bounds: {
            e: null,
            w: null,
            s: null,
            n: null,
        },

        level: 9,

        current: {
            lng: null,
            lat: null,
        },
    },

    mutations: {
        initLocation(state) {
            state.level = 6;
            state.current = { lng: null, lat: null };
            state.bounds = { e: null, w: null, s: null, n: null };
            state.center = { lng: 127.032693842117, lat: 37.4835924256371, sido: "서울", gugun: "강남구" };
        },

        setCenter(state, { lng = state.center.lng, lat = state.center.lat, sido = state.center.sido, gugun = state.center.gugun } = {}) {
            state.center = { lng, lat, sido, gugun };
        },
        setLevel(state, { level = 6 } = {}) {
            state.level = level;
        },
        setBounds(state, { e = null, w = null, s = null, n = null } = {}) {
            state.bounds = { e, w, s, n };
        },
        setCurrent(state, { lng = state.current.lng, lat = state.current.lat } = {}) {
            state.current = { lng, lat };
        },
    },

    actions: {
        async setSidoGugun({ state, commit }, { lng = state.center.lng, lat = state.center.lat, callback = () => {} } = {}) {
            try {
                // // 주소 셋업
                geocoder.coord2RegionCode(lng, lat, (results, status) => {
                    let sido = results?.[0]?.region_1depth_name;
                    if (sido.includes("북도") || sido.includes("남도")) sido = sido[0] + sido[2];
                    sido = sido.slice(0, 2);
                    const gugun = results?.[0]?.region_2depth_name.split(" ")[0];
                    commit("setCenter", { sido, gugun });
                    callback();
                });
            } catch (error) {
                console.error(error);
            }
        },
        async setCurrentCenter({ commit }, { callback = () => {} } = {}) {
            try {
                // 로케이션 셋업
                const { coords } = await getCurrentPosition();
                const { latitude: lat, longitude: lng } = coords;

                // 주소 셋업
                geocoder.coord2RegionCode(coords.longitude, coords.latitude, (results, status) => {
                    if (status == Status.OK) {
                        let sido = results?.[0]?.region_1depth_name;
                        if (sido.includes("북도") || sido.includes("남도")) sido = sido[0] + sido[2];
                        sido = sido.slice(0, 2);

                        const gugun = results?.[0]?.region_2depth_name;

                        commit("setCenter", { lng, lat, sido, gugun });
                        callback();
                    } else alert("위치를 확인할 수 없습니다");
                });
            } catch (error) {
                console.error(error);
                alert("위치 권한을 허용하신 뒤 다시 시도하십시오");
            }
        },
        async setAddressCenter({ commit }, { sido, gugun, callback = () => {} } = {}) {
            if (sido == "광주") sido = "광주광역시";
            const address = !!sido && !!gugun ? `${sido} ${gugun}` : gugun || sido;

            geocoder.addressSearch(address, (results, status) => {
                if (status == Status.OK) {
                    var lng = parseFloat(results?.[0]?.x),
                        lat = parseFloat(results?.[0]?.y);

                    commit("setCenter", { lng, lat, sido, gugun });
                    commit("setLevel", { level: !!gugun ? 6 : 9 });
                    callback();
                } else alert("위치를 확인할 수 없습니다");
            });
        },
    },
    getters: {
        kakaomapCenterPosition: ({ center: { lng, lat } }) => {
            return { lng, lat };
        },
        geolocation: ({ center: { lng, lat } }) => ({
            type: "Point",
            coordinates: [lng, lat],
        }),
        geometry: ({ bounds: { e, w, s, n } }) => {
            if (!!w && !!s && !!e && !!n) {
                return {
                    type: "Polygon",
                    coordinates: [
                        [
                            [e, s],
                            [w, s],
                            [w, n],
                            [e, n],
                            [e, s],
                        ],
                    ],
                };
            } else return null;
        },
    },
};
