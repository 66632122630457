module.exports = {

    // 공용
    common: {
        "로그인": "Login",
        "로그아웃": "Logout",
        "글쓰기": "글쓰기",
    },

    // 메인페이지
    main: {
        section1: "section1 content~! ㅇㅅㅇ",
        section2: {
            title: "section2 title~~~~",
            content: "section2 Content :)"
        }
    },

    mypage: {
        "회원정보수정": "my info"
    }
};