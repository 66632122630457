import { PURCHASE_ORDER_STATES, PURCHASE_ORDER_TYPES, PURCHASE_ORDER_UPDATE_COLUMN } from "../assets/variables/constants";
let { ORDERED } = PURCHASE_ORDER_STATES;
let { GIFTSET_ORDER, CUSTOMER_ORDER_BY_UNIT, CUSTOMER_ORDER_BY_BULK, STORE_ORDER_BY_COMPANY, STORE_ORDER_BY_COURIER, STORE_ORDER_BY_EXPRESS, BUNDANG_HANDLING_ORDER, ADMIN_UPLOADED_ORDER_BC, ADMIN_UPLOADED_ORDER_CU, ADMIN_UPLOADED_ORDER_DC, ADMIN_UPLOADED_ORDER_SH, ADMIN_MANUAL_ORDER_BULK, ADMIN_MANUAL_ORDER_UNIT } = PURCHASE_ORDER_TYPES;

export const PurchaseOrderingRouter = [
    /////////////////////////////////////////////////////////////////////////////////////
    //                                                                                 //
    //                               사용자 페이지 라우트                               //
    //                                                                                 //
    /////////////////////////////////////////////////////////////////////////////////////

    {
        // 세트발주
        path: "/purchase-ordering/giftset-order",
        component: () => import(/* webpackChunkName: "purchase-ordering" */ "../pages/client/purchase-ordering/GiftsetOrderPage.vue"),
        props: true,
    },
    {
        // 세트발주
        path: "/purchase-ordering/giftset-order/:tab",
        component: () => import(/* webpackChunkName: "purchase-ordering" */ "../pages/client/purchase-ordering/GiftsetOrderPage.vue"),
        props: true,
    },
    {
        // 택배발주
        path: "/purchase-ordering/customer-order",
        component: () => import(/* webpackChunkName: "purchase-ordering" */ "../pages/client/purchase-ordering/CustomerOrderPage.vue"),
        props: true,
    },
    {
        // 택배발주
        path: "/purchase-ordering/customer-order/:tab",
        component: () => import(/* webpackChunkName: "purchase-ordering" */ "../pages/client/purchase-ordering/CustomerOrderPage.vue"),
        props: true,
    },
    {
        // 특정발주
        path: "/purchase-ordering/store-order",
        component: () => import(/* webpackChunkName: "purchase-ordering" */ "../pages/client/purchase-ordering/StoreOrderPage.vue"),
        props: true,
    },
    {
        // 특정발주
        path: "/purchase-ordering/store-order/:tab",
        component: () => import(/* webpackChunkName: "purchase-ordering" */ "../pages/client/purchase-ordering/StoreOrderPage.vue"),
        props: true,
    },
    {
        // 분당발주
        path: "/purchase-ordering/bundang-order",
        component: () => import(/* webpackChunkName: "purchase-ordering" */ "../pages/client/purchase-ordering/BundangHandlingOrder.vue"),
        props: true,
    },
    {
        // 분당발주
        path: "/purchase-ordering/bundang-order/:tab",
        component: () => import(/* webpackChunkName: "purchase-ordering" */ "../pages/client/purchase-ordering/BundangHandlingOrder.vue"),
        props: true,
    },

    {
        // 발주이력
        path: "/mypage/purchase-orders",
        component: () => import(/* webpackChunkName: "purchase-ordering" */ "../pages/client/mypage/PurchaseOrderList.vue"),
        props: true,
    },
    {
        // 발주이력
        path: "/mypage/purchase-orders/:_order",
        component: () => import(/* webpackChunkName: "purchase-ordering" */ "../pages/client/mypage/PurchaseOrderView.vue"),
        props: true,
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //                                                                                 //
    //                               관리자 페이지 라우트                               //
    //                                                                                 //
    /////////////////////////////////////////////////////////////////////////////////////

    {
        // 관리자 - 상품관리 - 상품
        path: "/console/shop/products",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ShopProductList.vue"),
        scope: ["console"],
    },

    {
        // 관리자 - 상품관리 - 카테고리
        path: "/console/shop/categories",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ShopCategoryList.vue"),
        scope: ["console"],
    },

    {
        // 관리자 - 발주/택배 관리 - 미처리 발주
        path: "/console/shop/purchase-orders-to-process",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/PurchaseOrderList.vue"),
        scope: ["console"],
        props: () => ({
            sort: "createdAt_asc",
            title: "미처리 발주",
            excel: null,
            types: [GIFTSET_ORDER, STORE_ORDER_BY_COMPANY, STORE_ORDER_BY_COURIER, STORE_ORDER_BY_EXPRESS, BUNDANG_HANDLING_ORDER],
            states: [ORDERED],
        }),
    },
    {
        // 관리자 - 발주/택배 관리 - 발주 현황
        path: "/console/shop/purchase-orders-processed",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/PurchaseOrderList.vue"),
        scope: ["console"],
        props: () => ({
            title: "발주 현황",
            excel: "default",
            types: [GIFTSET_ORDER, STORE_ORDER_BY_COMPANY, STORE_ORDER_BY_COURIER, STORE_ORDER_BY_EXPRESS, BUNDANG_HANDLING_ORDER],
            states: Object.values(PURCHASE_ORDER_STATES).filter(({ value }) => value != ORDERED.value),
        }),
    },
    {
        // 관리자 - 발주/택배 관리 - 미처리 택배
        path: "/console/shop/purchase-orders-to-ship",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/PurchaseOrderList.vue"),
        scope: ["console"],
        props: () => ({
            sort: "createdAt_asc",
            title: "미처리 택배",
            excel: "upload",
            types: [CUSTOMER_ORDER_BY_UNIT, CUSTOMER_ORDER_BY_BULK, ADMIN_UPLOADED_ORDER_BC, ADMIN_UPLOADED_ORDER_CU, ADMIN_UPLOADED_ORDER_DC, ADMIN_UPLOADED_ORDER_SH, ADMIN_MANUAL_ORDER_BULK, ADMIN_MANUAL_ORDER_UNIT],
            states: [ORDERED],
        }),
    },
    {
        // 관리자 - 발주/택배 관리 - 택배 현황
        path: "/console/shop/purchase-orders-shipped",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/PurchaseOrderList.vue"),
        scope: ["console"],
        props: () => ({
            title: "택배 현황",
            excel: "invoice",
            types: [CUSTOMER_ORDER_BY_UNIT, CUSTOMER_ORDER_BY_BULK, ADMIN_UPLOADED_ORDER_BC, ADMIN_UPLOADED_ORDER_CU, ADMIN_UPLOADED_ORDER_DC, ADMIN_UPLOADED_ORDER_SH, ADMIN_MANUAL_ORDER_BULK, ADMIN_MANUAL_ORDER_UNIT],
            states: Object.values(PURCHASE_ORDER_STATES).filter(({ value }) => value != ORDERED.value),
            columns: Object.values(PURCHASE_ORDER_UPDATE_COLUMN)
        }),
    },

    {
        // 관리자 - 실시간 집계 - 일반
        path: "/console/shop/purchase-order-status/regular",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/PurchaseOrderStatusRegular.vue"),
        scope: ["console"],
    },
    {
        // 관리자 - 실시간 집계 - 롯데백화점
        path: "/console/shop/purchase-order-status/lotte-department-store",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/PurchaseOrderStatusLotteDepartmentStore.vue"),
        scope: ["console"],
    },
    {
        // 관리자 - 실시간 집계 - 분당배송
        path: "/console/shop/purchase-order-status/bundang-handling",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/PurchaseOrderStatusBundangHandling.vue"),
        scope: ["console"],
    },
    {
        // 관리자 - 발주/택배 관리 - 클레임
        path: "/console/shop/purchase-order-claims",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/PurchaseOrderClaimList.vue"),
        scope: ["console"],
    },
];
