export const UI_COMMNUITY_MODES = {
    GRID: "ui.community.mode:GRID",
    LIST: "ui.community.mode:LIST",
};

export const community = {
    namespaced: true,
    state: {
        mode: UI_COMMNUITY_MODES.GRID,
        showsSearch: false,
    },
    mutations: {
        setMode(state, mode) {
            if (Object.values(UI_COMMNUITY_MODES).includes(mode)) state.mode = mode;
            else alert(`요청하신 mode "${mode}"는 존재하지 않습니다.`);
        },
        setShowsSearch(state, showsSearch) {
            state.showsSearch = showsSearch;
        },
    },
    actions: {},
    modules: {},
};
