import axios from "@/plugins/axios";

let url = "/api/v1/shop/products";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    getByCodeAlt(codeAlt) {
        return axios.get(`${url}/get-by-code-alt/${codeAlt}`).then((result) => result.data);
    },
};
