import categories from "./categories";
import products from "./products";
import purchaseOrderClaims from "./purchase-order-claims";
import purchaseOrders from "./purchase-orders";

export default {
    categories,
    products,
    purchaseOrderClaims,
    purchaseOrders,
};
