import api from "../api";
import { location } from "./honja-location";

export const honja = {
    namespaced: true,
    state: {
        tags: [],
        coinQuiz: undefined,
    },
    mutations: {
        setTags(state, tags) {
            state.tags = tags;
        },
        setCoinQuiz(state, coinQuiz) {
            state.coinQuiz = coinQuiz;
        },
    },
    actions: {
        async getTags({ commit }) {
            const { tags } = await api.console.honja.tags.gets();
            commit("setTags", tags);
        },
        async getCoinQuiz({ commit }) {
            const { quiz } = await api.v1.honja.quizzes.get({ code: "coin-zone-main" });
            commit("setCoinQuiz", quiz);
        },
    },
    modules: {
        location,
    },
};
