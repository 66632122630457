import axios from "@/plugins/axios";

let url = "/api/v1/shop/reviews";

export default {
    getReviews(data){
        return axios.get(url, data).then(result => result.data);
    },
    postThumb(review, thumb){
        return axios.post(`${url}/${review._id}/thumbs`,thumb).then(result=>result.thumb)
    }
}
